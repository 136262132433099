import { renderAirlineFilterItem, renderCharterersFilterItem } from "./render";
import { Airline, Charterer, Flight } from "../../utils/types";
import { ui } from "../../utils/ui";

const sortFlights = (flights: Flight[], fields: any): Flight[] => {
    if (!flights) return [];

    const filteredFlights = flights.filter((flight) => {
        const {
            airlines,
            flightTime,
            shortStayDestination,
            newDestination,
            flightType,
            season,
            takeoffDay,
            returnDay,
            charterers,
        } = fields;

        if (airlines?.length > 0 && !airlines.includes(flight.airline)) return false;

        // Add filters for array charterer.
        if (charterers?.length > 0) {
            if (flight.charterersIds) {
                // @ts-ignore
                flight.charterersIds.forEach((charterer: number) => {
                    if (!charterers.includes(charterer)) return false;
                });
            } else {
                return false;
            }
        }

        if (shortStayDestination && !flight.shortStayDestination) return false;

        if (newDestination && !flight.newDestination) return false;

        if (flightType !== "all" && flight.type !== flightType) return false;

        if (flightTime !== 0 && flight.duration > flightTime) return false;

        if (season !== "all" && flight.season !== season) return false;

        if (takeoffDay !== "0" && takeoffDay !== "any" && flight.takeoffDay !== takeoffDay) return false;

        if (returnDay !== "0" && returnDay !== "any" && flight.returnDay !== returnDay) return false;

        return true;
    });

    return filteredFlights;
};

const populateAirlineFilter = (airlines: Airline[]) => {
    const sortedAirlines = Object.values(airlines).sort((a, b) => a.name.localeCompare(b.name));

    const renderedAirlines = sortedAirlines
        .map((airline) => {
            const { id, name } = airline;
            if (!id || !name) return;
            return renderAirlineFilterItem(id, name);
        })
        .join("");

    ui.filter.fields.airlines.innerHTML =
        renderedAirlines?.length > 0 ? renderedAirlines : "Aucune compagnie disponible.";
};

const populateCharterersFilter = (charterers: Charterer[]) => {
    const sortedCharterers = Object.values(charterers).sort((a, b) => a.name.localeCompare(b.name));

    const renderedCharterers = sortedCharterers
        .map((charterer) => {
            const { id, name } = charterer;
            if (!id || !name) return;
            return renderCharterersFilterItem(id, name);
        })
        .join("");

    ui.filter.fields.charterers.innerHTML =
        renderedCharterers?.length > 0 ? renderedCharterers : "Aucune compagnie disponible.";
};

export { sortFlights, populateAirlineFilter, populateCharterersFilter };
