export default function inwhishlist(addToWishlists: any) {
    const lang = document.documentElement.lang;
    const loader = '<div class="ajax-progress ajax-progress-fullscreen">&nbsp;</div>';
    let parser = new DOMParser();
    const translations = window.wishlistsTranslations;

    const pageDestination = document.querySelector('.page-node-type-destination') as HTMLElement;
    const openWishlistPanel = document.querySelectorAll('.add-to-wishlist:not(.open-login)');
    const wishlistPanel = document.querySelector('.wishlist-panel') as HTMLElement;
    const underlay = document.querySelector('.wishlist-panel .underlay') as HTMLElement;
    const close = wishlistPanel?.querySelector('.close-filters') as HTMLElement;
    const addToWishlistBtns = document.querySelectorAll('.add-destination-to-wishlist');
    const validateAddTo = document.querySelector('.validate-addto-list') as HTMLElement;

    const blockWishlists = document.querySelector('#block-wishlistuserlistblock') as HTMLElement;

    const editNew = document.querySelector('.create-new-list') as HTMLElement;
    const editNewPanel = document.querySelector('.wishlist-panel__create') as HTMLElement;

    const removePanel = wishlistPanel?.querySelector('.wishlist-panel__remove') as HTMLElement;
    const closeRemovePanel = wishlistPanel?.querySelectorAll('.close-remove-edit');
    const removeButton = wishlistPanel?.querySelector('.delete-this-list-button') as HTMLElement;

    // Page of a wishlist.
    const pagewishlist = document.querySelector('.wishlist--full') as HTMLElement;
    const wishlistId = pagewishlist?.dataset.listid;
    const shareWishlist = pagewishlist?.querySelector('.share-this-list') as HTMLElement;
    const renameWishlist = pagewishlist?.querySelector('.rename-this-list') as HTMLElement;
    const deleteWishlist = pagewishlist?.querySelector('.delete-this-list') as HTMLElement;
    const accountListUrl = `/${lang}/account/wishlists`;
    const copyToClipboard = wishlistPanel?.querySelector('.copy-link') as HTMLElement;
    const sharePanel = wishlistPanel?.querySelector('.wishlist-panel__share') as HTMLElement;
    const linkPanel = wishlistPanel?.querySelector('.wishlist-panel__link') as HTMLElement;
    const createLink = wishlistPanel?.querySelector('.create-a-link') as HTMLElement;
    const addDestination = pagewishlist?.querySelector('.add-a-destination') as HTMLElement;
    const addAllToWishlist = wishlistPanel?.querySelector('.add-all-to-wishlist') as HTMLElement;

    // Page account wishlists.
    const createNew = document.querySelectorAll('.open-create-panel');
    function openCreateNew() {
        // Page account wishlists
        const createNew = document.querySelectorAll('.open-create-panel');
        if (createNew.length > 0) {
            createNew.forEach((element: Element) => {
                const elem = element as HTMLElement;
                elem?.addEventListener('click', () => {
                    newList();
                });
            });
        }
    }
    openCreateNew();


    // copy to clipboard
    function copyText(element: any) {
        let textToCopy = element.value;
        navigator.clipboard.writeText(textToCopy);
    }

    // Check if inwhislist.
    function inWishlist(addToWishlists: any) {
        // Add class on destination if in wishlist.
        addToWishlists?.forEach((element: HTMLElement) => {
            const nodeid = element.dataset?.nodeid;
            fetch(`/${lang}/ajax/in-wishlist`, {
                method: "POST",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(nodeid)
            })
                .then(response => response.json())
                .then(function (json) {
                    let banner = null;
                    if (pageDestination) {
                        banner = pageDestination.querySelector('.banner__actions .add-to-wishlist') as HTMLElement;
                    }
                    if (json.inwishlist) {
                        element.classList.add('in-wishlist');
                        if (banner) {
                            banner.classList.add('in-wishlist');
                        }
                    } else {
                        element.classList.remove('in-wishlist');
                        if (banner) {
                            banner.classList.remove('in-wishlist');
                        }
                    }
                });
        });
    }

    function closeAllPanels() {
        const allPanels = document.querySelectorAll('.subpanel');
        allPanels.forEach((element: Element) => {
            const panel = element as HTMLElement;
            panel.removeAttribute('style');
        });
    }

    // add to triggers.
    function activateAddTo(addToWishlistBtns: any) {
        // Add to wishlist.
        addToWishlistBtns?.forEach((element: any) => {
            let checkbox = element.querySelector('.form-checkbox') as HTMLInputElement;
            checkbox.addEventListener('change', (event: Event) => {
                //event.preventDefault();
                const elem = event.target as HTMLInputElement;
                const parent = elem.closest('.a-list') as HTMLElement;
                if (parent.dataset.wishlistid && parent.dataset.currentdesti) {
                    if (elem.checked != true) {
                        parent.classList.remove('already-in');
                    }
                }
                return false;
            });
        });
    }

    // On load.
    inWishlist(addToWishlists);
    activateAddTo(addToWishlistBtns);

    // Function add to wishlist
    function addDestinationToWishlists(destination_id: string, wishlists: any) {
        fetch(`/${lang}/ajax/add-destination-to-wishlists/${destination_id}`, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(wishlists)
        })
            .then(response => response.json())
            .then(function (json) {
                if (json.status == true) {
                    updateWishlists('', true);
                    closeAllPanels();
                }
            });
    }

    // Function to remove destination from wishlist.
    function removeDestinationFromWishlist(wishlist_id: string, destination_id: string) {
        fetch(`/${lang}/ajax/remove-from-wishlist/${destination_id}/${wishlist_id}`)
            .then(response => response.json())
            .then(function (json) {
                if (json.status == true) {
                    updateWishlists('', undefined, true);
                }
            });
    }

    // Rename panel when edit.
    function changeRenamePanel(editNewPanel: HTMLElement, wishlist_id: string, name: string) {
        let title = editNewPanel.querySelector('[name="title"]') as HTMLInputElement;
        let wishlist_id_input = editNewPanel.querySelector('[name="wishlist_id"]') as HTMLInputElement;
        let panelTitle = editNewPanel.querySelector('.heading--medium') as HTMLElement;
        let panelButton = editNewPanel.querySelector('[data-twig-suggestion="edit-wishlist"]') as HTMLInputElement;
        if (title) {
            title.value = `${name}`;
        }
        if (wishlist_id_input) {
            wishlist_id_input.value = `${wishlist_id}`;
        }
        if (panelTitle) {
            panelTitle.textContent = translations.rename;
        }
        if (panelButton) {
            panelButton.textContent = translations.buttonRename;
        }
    }

    function editWishlistsEvents() {
        let edits = document.querySelectorAll('.edit-this-list');
        if (edits.length > 0) {
            edits?.forEach((element: any) => {
                element.addEventListener('click', (event: Event) => {
                    event.preventDefault();
                    const elem = event.target as HTMLElement;
                    const parent = elem.closest('.a-list') as HTMLElement;
                    const wishlist_id = parent.dataset.wishlistid;
                    const name = parent.querySelector('span')?.textContent?.trim();
                    if (editNewPanel) {
                        editNewPanel.setAttribute('style', 'margin-right: 0;');
                        changeRenamePanel(editNewPanel, `${wishlist_id}`, `${name}`);
                    }
                    return false;
                });
            });
        }

        let deletes = document.querySelectorAll('.delete-this-list');
        if (deletes.length > 0) {
            deletes?.forEach((element: any) => {
                element.addEventListener('click', (event: Event) => {
                    event.preventDefault();
                    const elem = event.target as HTMLElement;
                    const parent = elem.closest('.a-list') as HTMLElement;
                    const wishlist_id = parent?.dataset.wishlistid;
                    removePanel?.setAttribute('style', 'margin-right: 0;');
                    let removeButton = removePanel?.querySelector('.delete-this-list-button') as HTMLElement;
                    removeButton?.setAttribute('data-wishlist', `${wishlist_id}`);
                    return false;
                });
            });
        }
    }
    editWishlistsEvents();

    // Update wishlists.
    function updateWishlists(currentDesti?: string, msg?: boolean, reload?: boolean, close?: boolean) {
        if (blockWishlists) {
            blockWishlists.innerHTML = ''
            let load = parser.parseFromString(loader, 'text/html');
            let loading = load.querySelector('.ajax-progress') as HTMLElement;
            blockWishlists.append(loading);
        }

        let templateDestination = document.querySelector('.template-destination') as HTMLElement;
        let currentDestinationId = 'account';
        if (templateDestination) {
            currentDestinationId = `${templateDestination.getAttribute('data-node-id')}`;
        }
        if (currentDesti) {
            currentDestinationId = currentDesti;
        }
        if (blockWishlists && blockWishlists.dataset.currentfavnid) {
            currentDestinationId = blockWishlists.dataset.currentfavnid;
        }
        // Call to service.
        fetch(`/${lang}/ajax/refresh-wishlists/${currentDestinationId}`, {
            method: "POST",
            headers: {'Content-Type': 'application/json'}
        }).then(function (response) {
            return response.text();
        }).then(function (html) {
            if (blockWishlists) {
                blockWishlists.innerHTML = '';
                let doc = parser.parseFromString(html, 'text/html');
                let insert = doc.querySelector('.list') as HTMLElement;
                blockWishlists.append(insert);
            }
            let addToWishlistBtnsReloaded = document.querySelectorAll('.add-destination-to-wishlist');
            let checkIntoWishlists = document.querySelectorAll('.actions .add-to-wishlist:not(.open-login)');
            inWishlist(checkIntoWishlists);
            activateAddTo(addToWishlistBtnsReloaded);
            editWishlistsEvents();
            const editNewEvent = wishlistPanel?.querySelector('.create-new-list') as HTMLElement;
            editNewFormEvent(editNewEvent);

            if (msg) {
                const list = document.querySelector('.wishlist-panel .wishlists-user__cards.list') as HTMLElement;
                if (list) {
                    let current_succ = list?.querySelector('.success-text') as HTMLElement;
                    current_succ?.remove();
                    let successMsg = `<span class="success-text">${translations.successModified}</span>`;
                    let succ = parser.parseFromString(successMsg, 'text/html');
                    let success = succ.querySelector('.success-text') as HTMLElement;
                    list.prepend(success);
                }
            }
            if (close) {
                let n: ReturnType<typeof setTimeout>;
                n = setTimeout(function() {
                    wishlistPanel.classList.remove('active');
                }, 500);
            }
            if (reload) {
                window.location.reload();
            }
            return false;
        })

    }

    // Event to open and close panel.
    if (openWishlistPanel.length > 0) {
        openWishlistPanel.forEach((element: any) => {
            element.addEventListener('click', (event: Event) => {
                event.preventDefault();
                const elem = event.target as HTMLElement;
                const parent = elem?.closest('.card.destination') as HTMLElement;
                const page = elem?.closest('.wishlist--full') as HTMLElement
                if (page) {
                    const wishlist_id = `${page.dataset.listid}`;
                    const destination_id = `${element.dataset.nodeid}`;
                    removeDestinationFromWishlist(wishlist_id, destination_id);
                    let load = parser.parseFromString(loader, 'text/html');
                    let loading = load.querySelector('.ajax-progress') as HTMLElement;
                    let currentLoading = page.querySelector('.ajax-progress') as HTMLElement;
                    if (!currentLoading) {
                        page.append(loading);
                    }
                }
                else {
                    if (!elem.classList.contains('in-wishlist')) {
                        wishlistPanel?.classList.add('active');
                    }

                    const addTitle = document.querySelector('.wishlist-panel__header .add-destination') as HTMLElement;
                    const manageTitle = document.querySelector('.wishlist-panel__header .manage-destination') as HTMLElement;
                    if (addTitle && manageTitle) {
                        addTitle.removeAttribute('style');
                        manageTitle.removeAttribute('style');
                        if (element.classList.contains('in-wishlist')) {
                            addTitle.setAttribute('style', 'display: none');
                            manageTitle.setAttribute('style', 'display: block');
                        }
                    }

                    // Context when click on card.
                    if (parent) {
                        const destiTitleElem = parent.querySelector('.card__title span') as HTMLElement;
                        const destiTitle = destiTitleElem?.textContent;
                        const panelName = wishlistPanel?.querySelectorAll('.wishlist-panel__header .heading--medium em');
                        panelName.forEach((elem: Element) => {
                           const element = elem as HTMLElement;
                            element.innerText = `${destiTitle}`;
                        });
                        blockWishlists.setAttribute('data-currentfavnid', element.dataset.nodeid);
                        updateWishlists(element.dataset.nodeid);
                    }
                    else {
                        const destiTitleElem = document.querySelector('.top-content__header .heading--1 span') as HTMLElement;
                        const destiTitle = destiTitleElem?.textContent;
                        const panelName = wishlistPanel?.querySelectorAll('.wishlist-panel__header .heading--medium em');
                        panelName.forEach((elem: Element) => {
                            const element = elem as HTMLElement;
                            element.innerText = `${destiTitle}`;
                        });
                        blockWishlists.removeAttribute('data-currentfavnid');
                        updateWishlists();
                    }
                }
                return false;
            });
        });
    }

    close?.addEventListener('click', () => {
        if (wishlistPanel) {
            wishlistPanel?.classList.remove('active');
        }
    });
    underlay?.addEventListener('click', () => {
        if (wishlistPanel) {
            wishlistPanel.classList.remove('active');
            closeAllPanels();
        }
    });

    // Open form.
    function editNewFormEvent(editNew: any) {
        editNew?.addEventListener('click', () => {
            if (editNewPanel) {
                editNewPanel.setAttribute('style', 'margin-right: 0;');
                const title = editNewPanel.querySelector('[name="title"]') as HTMLInputElement;
                const wishlist_id_input = editNewPanel.querySelector('[name="wishlist_id"]') as HTMLInputElement;
                wishlist_id_input.value = '';
                title.value = '';
            }
        });
    }

    editNewFormEvent(editNew);

    // Close form.
    function closeNewCreatePanel() {
        const closeNewPanel = document.querySelectorAll('.close-new-edit');
        if (closeNewPanel.length > 0) {
            closeNewPanel.forEach((element: Element) => {
                const elem = element as HTMLElement;
                elem.onclick = (event: MouseEvent) => {
                    event.preventDefault();
                    if (editNewPanel) {
                        closeAllPanels();
                    }
                    if (pagewishlist || createNew.length > 0) {
                        wishlistPanel.classList.remove('active');
                    }
                    return false;
                };
            })
        }
    }
    closeNewCreatePanel();

    function editFormNew() {
        const formEditNew = document.querySelector('.whishlist-create-form') as HTMLElement;
        // Submit edit new.
        const title = formEditNew?.querySelector('[name="title"]') as HTMLInputElement;
        const wishlist_id_input = formEditNew?.querySelector('[name="wishlist_id"]') as HTMLInputElement;
        const btnSubmit = formEditNew?.querySelector('[data-twig-suggestion="edit-wishlist"]') as HTMLElement;
        const pagewishlist = document.querySelector('.wishlist--full') as HTMLElement;
        const sectionWishlistUser = document.querySelector('section.wishlists-user') as HTMLElement;
        const desti: HTMLElement|null = document.querySelector('.template-destination[data-node-id]');

        if (title) {
            title.addEventListener('keyup', () => {
                if (title.value) {
                    title.classList.remove('error');
                }
            });
            window.trigger = true;
            btnSubmit.addEventListener('click', (event: MouseEvent) => {
                event.preventDefault();
                if (window.trigger) {
                    let errors = [];
                    if (!title.value) {
                        errors.push('value');
                        title.classList.add('error');
                    }
                    if (title.value && title.value.length > 50) {
                        errors.push('length')
                        title.classList.add('error');
                    }
                    let wishlist = '';
                    if (wishlist_id_input) {
                        wishlist = wishlist_id_input.value;
                    }

                    let nodeid: string|undefined = desti?.dataset.nodeId;
                    if (!desti) {
                        const addDestiList: HTMLElement|null = document.querySelector('[data-currentfavnid]');
                        nodeid = addDestiList?.dataset.currentfavnid;
                    }

                    if (errors.length == 0) {
                        let params = {
                            text: title.value,
                            wishlist: wishlist,
                            nodeid: nodeid
                        };
                        fetch(`/${lang}/ajax/create-edit-wishlist`, {
                            method: "POST",
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify(params)
                        }).then(response => response.json())
                            .then(function (json) {
                                if (json.created) {
                                    if (pagewishlist) {
                                        let load = parser.parseFromString(loader, 'text/html');
                                        let loading = load.querySelector('.ajax-progress') as HTMLElement;
                                        let currentLoading = pagewishlist.querySelector('.ajax-progress') as HTMLElement;
                                        if (!currentLoading) {
                                            pagewishlist.append(loading);
                                        }
                                        window.location.reload();
                                    } else {
                                        const target = wishlistPanel.querySelector('.target-create-msg') as HTMLElement;
                                        let current_success = target?.querySelector('.success-text') as HTMLElement;
                                        current_success?.remove();
                                        let successMsg = `<span class="success-text">${json.message}</span>`;
                                        let succ = parser.parseFromString(successMsg, 'text/html');
                                        let success = succ.querySelector('.success-text') as HTMLElement;
                                        if (target) {
                                            target.prepend(success);
                                        }
                                        closeAllPanels();
                                    }
                                    if (editNewPanel) {
                                        closeAllPanels();
                                    }
                                    if (pagewishlist || sectionWishlistUser) {
                                        updateWishlists('', false, true);
                                    } else {
                                        updateWishlists('', false);
                                    }

                                }
                                if (json.error) {
                                    const target = wishlistPanel.querySelector('.target-create-msg') as HTMLElement;
                                    if (target) {
                                        target.innerHTML = '';
                                    }
                                    title.classList.add('error');
                                    let parent = title.closest('.js-form-item') as HTMLElement;
                                    let currentmsg = parent.querySelector('.error-text') as HTMLElement;
                                    currentmsg?.remove();
                                    let errorMsg = `<span class="error-text">${json.message}</span>`;
                                    let err = parser.parseFromString(errorMsg, 'text/html');
                                    let error = err.querySelector('.error-text') as HTMLElement;
                                    parent.prepend(error);
                                }
                            })
                    }

                    window.trigger = false;
                }
                return false;
            });
        }
    }
    editFormNew();

    validateAddTo?.addEventListener('click', () => {
        let destination_id = '';
        const page = document.querySelector('.template-destination') as HTMLElement;
        const blockWishlist = document.querySelector('#block-wishlistuserlistblock') as HTMLElement;
        if (blockWishlist) {
            if (blockWishlist.dataset.currentfavnid) {
                destination_id = `${blockWishlist.dataset.currentfavnid}`;
            }
            else {
                destination_id = `${page.getAttribute('data-node-id')}`;
            }
        }
        const allCheckedDestinations = document.querySelectorAll('.wishlists-user__cards .form-checkbox:checked');
        let values = [] as any;
        allCheckedDestinations.forEach((element: Element) => {
            const check = element as HTMLInputElement;
            values.push(check.value);
        });
        addDestinationToWishlists(destination_id, values);

    });

    // Remove.
    closeRemovePanel?.forEach((element: any) => {
        element.addEventListener('click', () => {
            closeAllPanels();
            if (pagewishlist) {
                wishlistPanel.classList.remove('active');
            }
        });
    });
    removeButton?.addEventListener('click', (event: MouseEvent) => {
        event.preventDefault();
        const elem = event.target as HTMLElement;
        const wishlist_id = elem.dataset.wishlist;
        fetch(`/${lang}/ajax/delete-wishlist/${wishlist_id}`, {
            method: "GET",
            headers: {'Content-Type': 'application/json'},
        }).then(response => response.json())
            .then(function (json) {
                if (json.deleted) {
                    // If in wishlist page.
                    if (pagewishlist) {
                        window.location.href = accountListUrl;
                        return;
                    }
                    // Update list on page destination.
                    updateWishlists();
                    closeAllPanels();
                } else {
                    console.log('error', json.error);
                }
            });
        return false;
    });

    // Functions on page wishlist.
    function shareList(wishlistId: string, shareWishlist: HTMLElement) {
        wishlistPanel?.classList.add('active');
        if (shareWishlist.dataset.shareid) {
            linkPanel?.setAttribute('style', 'margin-right:0');
            let url = linkPanel?.querySelector('[name="url"]') as HTMLInputElement;
            url.value = `${window.location.href}?share=${shareWishlist.dataset.shareid}`;
        } else {
            sharePanel?.setAttribute('style', 'margin-right:0');
        }
        createLink?.addEventListener('click', () => {
            fetch(`/${lang}/ajax/share-wishlist/${wishlistId}`, {
                method: "GET",
                headers: {'Content-Type': 'application/json'},
            }).then(response => response.json())
                .then(function (json) {
                    if (json.share) {
                        let setUrl = `${window.location.href}?share=${json.message}`;
                        wishlistPanel.classList.add('active');
                        linkPanel?.setAttribute('style', 'margin-right:0');
                        let url = linkPanel?.querySelector('[name="url"]') as HTMLInputElement;
                        url.value = setUrl;
                    } else {
                        console.log('error', json.error);
                    }
                });
        });
        return false;
    }

    function newList() {
        wishlistPanel?.classList.add('active');
        const renamePanel = wishlistPanel?.querySelector('.wishlist-panel__create') as HTMLElement;
        renamePanel?.setAttribute('style', 'margin-right: 0');
    }

    function renameList(wishlistId: string) {
        wishlistPanel?.classList.add('active');
        const renamePanel = wishlistPanel?.querySelector('.wishlist-panel__create') as HTMLElement;
        renamePanel?.setAttribute('style', 'margin-right: 0');
        let name = pagewishlist?.querySelector('h1')?.textContent;
        changeRenamePanel(renamePanel, `${wishlistId}`, `${name}`);
    }

    function deleteList(wishlistId: string) {
        wishlistPanel?.classList.add('active');
        const removePanel = wishlistPanel?.querySelector('.wishlist-panel__remove') as HTMLElement;
        const deleteButton = removePanel?.querySelector('.delete-this-list-button') as HTMLElement;
        removePanel?.setAttribute('style', 'margin-right: 0');
        deleteButton?.setAttribute('data-wishlist', wishlistId);
    }

    function openDestination() {
        wishlistPanel?.classList.add('active');
        const addDestinationPanel = wishlistPanel?.querySelector('.wishlist-panel__add') as HTMLElement;
        addDestinationPanel?.setAttribute('style', 'margin-right: 0');
    }

    function addAllItemToWishlist(wishlistId: string) {
        wishlistPanel?.classList.add('active');
        const addDestinationPanel = wishlistPanel?.querySelector('.wishlist-panel__add') as HTMLElement;
        addDestinationPanel?.setAttribute('style', 'margin-right: 0');
        const allCheckedDestinations = document.querySelectorAll('.form-checkbox:checked');
        let values = [] as any;
        allCheckedDestinations.forEach((element: Element) => {
            const check = element as HTMLInputElement;
            values.push(check.value);
        });

        fetch(`/${lang}/ajax/add-multiple-to-wishlist/${wishlistId}`, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(values)
        })
            .then(response => response.json())
            .then(function (json) {
                if (json.status == true) {
                    window.location.reload();
                }
            });

    }

    function allAccordions() {
        const accordions = document.querySelectorAll('.accordion-trigger');
        accordions.forEach((element: Element) => {
            const elem = element as HTMLElement;
            if (elem.classList.contains('accordion-trigger')) {
                elem.onclick = (event: MouseEvent) => {
                    const trigger = event.target as HTMLElement;
                    if (trigger.classList.contains('open')) {
                        trigger.classList.remove('open');
                    } else {
                        trigger.classList.add('open');
                    }
                };
            }
        });
    }

    if (pagewishlist) {
        shareWishlist?.addEventListener('click', () => {
            shareList(`${wishlistId}`, shareWishlist);
        });
        renameWishlist?.addEventListener('click', () => {
            renameList(`${wishlistId}`);
        });
        deleteWishlist?.addEventListener('click', () => {
            deleteList(`${wishlistId}`);
        });
        copyToClipboard?.addEventListener('click', (event: MouseEvent) => {
            const elem = event.target as HTMLElement;
            const target = wishlistPanel?.querySelector('[name=url]') as HTMLInputElement;
            copyText(target);
            elem.textContent = translations.copied;
        });
        if (addDestination) {
            addDestination.addEventListener('click', () => {
                openDestination();
            });
        }

        allAccordions();

        addAllToWishlist.onclick = () => {
            const destinationList = document.querySelector('[data-wishlistid]') as HTMLElement;
            let wishlistId = destinationList.dataset.wishlistid;
            addAllItemToWishlist(`${wishlistId}`);
        };
    }

    // Event dedicated for guidance.
    const guidanceTarget = document.querySelector('[data-target="list"]') as HTMLElement;
    if (guidanceTarget) {
        guidanceTarget.addEventListener('DOMSubtreeModified', (event: Event) => {
            const elem = event.target as HTMLElement;
            const flights = elem.querySelector('.destination-by') as HTMLElement;
            if (flights)  {
                const resultsFlight = document.querySelectorAll('.card.destination .add-to-wishlist');
                if (resultsFlight.length > 0) {
                    inWishlist(resultsFlight);
                }
            }
        });
    }

    // Check for ajax and reload Events.
    let blocwi = document.querySelector('.wishlist-panel') as HTMLElement;
    if (blocwi) {
        blocwi.addEventListener('DOMSubtreeModified', (event) => {
            const target = event.target as HTMLElement;
            if (target.classList.contains('counter')) {
                editFormNew();
            }
        });
    }

    // Back arrow.
    if (pagewishlist) {
        if (document.referrer.includes(window.location.host)) {
            const backArrow = document.querySelector('.back-arrow') as HTMLElement;
            backArrow.setAttribute('style', 'opacity: 1;pointer-events: initial;');
        }
    }

    // Change when dom is inserted.
    document.addEventListener('DOMNodeInserted', (event) => {
        const target = event.target as HTMLElement;
        if (target.id == "block-wishlistuserlistblock") {
            openCreateNew()
        }
        if (target.id == "block-wishlistcreateblock") {
            closeNewCreatePanel();
        }
        if (target.id == "block-wishlistdestinationlistblock") {
            allAccordions();
        }

    });


}
