// @ts-ignore
import {Datepicker} from 'vanillajs-datepicker';
// @ts-ignore
import fr from "vanillajs-datepicker/locales/fr";
// @ts-ignore
import en from "vanillajs-datepicker/locales/en-GB";

export default async function followedFlights(accountFollowedFlights: Element) {
    let url_ajax = '/' + window.drupalSettings.path.pathPrefix + 'ajax/account/get-followed-flights';
    const url_follow_flight = '/' + window.drupalSettings.path.pathPrefix + 'ajax/account/follow-flight';
    const translations = window.followedFlightsTranslations;
    const lang = document.documentElement.lang;

    // Test for limit on dashboard.
    const parent = accountFollowedFlights?.closest('.dash--entry.bookings') as HTMLElement;
    if (parent) {
        url_ajax = `${url_ajax}/2`;
    }
    await getFollowedFlights();

    let followFlightPopup = document.querySelector(`#follow-flight-popup`) as HTMLElement;
    let departureDateInput = document.querySelector(`#departure-date-hidden`) as HTMLInputElement;

    function blockCtaOpenPopup() {
        let followFlightPopup = document.querySelector(`#follow-flight-popup`) as HTMLElement;
        let closePopup = document.querySelector(`.follow-flight-popup__close`) as HTMLElement;
        const underlay = document.querySelector('#follow-flight-popup .underlay') as HTMLElement;
        let blockCtaOpenPopup = document.querySelector(`.cta-follow-flight`) as HTMLElement;

        if (blockCtaOpenPopup) {
            blockCtaOpenPopup.addEventListener('click', (event) => {
                event.preventDefault();
                followFlightPopup.classList.add('active');
            });
        }
        if (closePopup) {
            closePopup.addEventListener('click', (event) => {
                event.preventDefault();
                followFlightPopup.classList.remove('active');
            });
        }
        if (underlay) {
            underlay.addEventListener('click', () => {
                if (followFlightPopup) {
                    followFlightPopup.classList.remove('active');
                    followFlightPopup.removeAttribute('style');
                }
            });
        }
    }
    blockCtaOpenPopup();

    Object.assign(Datepicker.locales, fr, en);
    const today = new Date();
    let minDate = new Date();
    let maxDate = new Date(today.setDate(today.getDate() + 2));
    const datePickerElem = document.querySelector('#js-follow-flight-datepicker');
    if (datePickerElem) {
        const datePicker = new Datepicker(datePickerElem, {
            format: 'D dd M yy',
            minDate: minDate,
            maxDate: maxDate,
            defaultViewDate: today,
            language: lang,
            autohide: true,
        });
    }
    datePickerElem?.addEventListener('changeDate', (event: any) => {
        let choseDate = event.detail.date;
        departureDateInput.value = Datepicker.formatDate(choseDate, 'yyyy-mm-dd');
    });

    function triggerDetails() {
        const triggers = document.querySelectorAll('.see-info');
        triggers.forEach((el:Element) => {
            const trigger = el as HTMLElement;
            trigger.addEventListener('click',function(event: MouseEvent) {
                const elem = event.target as HTMLElement;
                const parent = elem.closest('.info-to-see') as HTMLElement;
                if (elem.classList.contains('open')) {
                    parent.classList.remove('info-is-visible')
                    elem.classList.remove('open');
                } else {
                    parent.classList.add('info-is-visible')
                    elem.classList.add('open');
                }

                event.stopPropagation();
                event.preventDefault();
            }, false);
        });
    }

    async function getFollowedFlights() {

        fetch(url_ajax, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
        })
        .then(function(response) {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.text();
        })
        .then(function(result) {
            processFlights(JSON.parse(result));
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    async function processFlights(response: any) {

        let followed_flights_block = document.querySelector("#followed-flights-block .followed-flights-listing__flights") as HTMLElement;
        followed_flights_block.innerHTML = '';

        if (response.length !== 0) {
            Object.values(response).forEach((flight: any) => {
                let dayflight_markup = document.createElement('div');
                dayflight_markup.classList.add('card-flight', 'info-to-see');
                dayflight_markup.innerHTML = flight;
                followed_flights_block.append(dayflight_markup);
            });

            triggerDetails();
            triggerFollowingButtons();
        } else {
            followed_flights_block.innerHTML = `<div class="is-empty">${translations.no_followed_flights}</div>`;
        }
    }

    function triggerFollowingButtons() {
        const followButtons = document.querySelectorAll(`.card-flight__follow:not(.open-login)`);
        followButtons.forEach((el: Element) => {
            const trigger = el as HTMLElement;
            trigger.addEventListener('click', () => {
                followFlight(el);
            },false);
        });
    }

    async function followFlight(elem: any) {

        // Call ajax.
        let data = {
            dayflight_id: elem.getAttribute("data-dayflight-id"),
        };

        fetch(url_follow_flight, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        })
        .then(function(response) {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(function() {
            location.replace(document.URL);
        }).catch(function(error) {
            console.log(error);
        });
    }

    // Change when dom is inserted.
    document.addEventListener('DOMNodeInserted', (event) => {
        const target = event.target as HTMLElement;
        if (target.id == "block-ntefollowflight") {
            blockCtaOpenPopup();
        }
    });
}
