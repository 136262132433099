import { data } from "../../utils/data";
import { ui } from "../../utils/ui";

const getFlightsData = () => {
    return data?.flights?.value ? JSON.parse(data.flights.value) : null;
};

const getFilterFields = () => {
    const season = getFieldSeason();
    const shortStayDestination = getFieldShortStayDestination();
    const newDestination = getFieldNewDestination();
    const airlines = getFieldAirlines();
    const flightType = getFieldFlightType();
    const flightTime = getFieldFlightTime();
    const takeoffDay = getFieldTakeoffDay();
    const returnDay = getFieldTakeoffDay();
    const charterers = getFieldCharterers();

    return { season, shortStayDestination, newDestination, airlines, flightType, flightTime, takeoffDay, returnDay, charterers };
};

const getFieldSeason = (): string => {
    const season = ui?.filter?.fields?.season?.find((input) => input.checked);
    return season ? season.value : "all";
};

const getFieldShortStayDestination = (): boolean => {
    return ui?.filter?.fields?.shortStayDestination?.checked ? ui.filter.fields.shortStayDestination.checked : false;
};

const getFieldNewDestination = (): boolean => {
    return ui?.filter?.fields?.newDestination?.checked ? ui.filter.fields.newDestination.checked : false;
};

const getFieldAirlines = (): number[] => {
    const airlines: number[] = [];
    const options = Array.from(
        ui?.filter?.fields?.airlines.querySelectorAll('input[name="airline"]')
    ) as HTMLInputElement[];

    options?.map((option) => option.checked && airlines.push(parseInt(option.value)));

    return airlines;
};

const getFieldCharterers = (): number[] => {
    const charterers: number[] = [];
    const options = Array.from(
        ui?.filter?.fields?.charterers.querySelectorAll('input[name="charterer"]')
    ) as HTMLInputElement[];

    options?.map((option) => option.checked && charterers.push(parseInt(option.value)));
    return charterers;
};

const getFieldFlightType = (): string | null => {
    const flightType = ui?.filter?.fields?.flightType?.find((input) => input.checked);
    return flightType ? flightType.value : "all";
};

const getFieldFlightTime = (): number => {
    return ui?.filter?.fields?.flightTime?.value ? parseInt(ui.filter.fields.flightTime.value) : 0;
};

const getFieldTakeoffDay = (): string => {
    return ui?.search?.fields?.departureDays?.value ?? 'any';
}
const getFieldReturnDay = (): string => {
    return ui?.search?.fields?.returnDays?.value ?? 'any';
}

export {
    getFlightsData,
    getFilterFields,
    getFieldSeason,
    getFieldShortStayDestination,
    getFieldNewDestination,
    getFieldAirlines,
    getFieldFlightType,
    getFieldFlightTime,
};
